import React from "react"
import {
  EmailIcon,
  FacebookIcon,
 
  LinkedinIcon,
  
  PinterestIcon,
  RedditIcon,
  
 
  XIcon,
} from "react-share";
import  { useState, useEffect } from 'react';
import {
  EmailShareButton,
  FacebookShareButton,

  LinkedinShareButton,
  
  RedditShareButton, TwitterShareButton
 
} from "react-share";
const SocialMediaButton = ({ Icon, ShareButton, shareUrl, description, hoverColor }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyles = {
    fill: isHovered ? hoverColor : "#1A202C",
    transition: "fill .2s",
    
  };
  


  return (
    <ShareButton url={shareUrl} quote={description} body={`Read The Article:`} >
      <Icon
        size={37}
        round={true}
        bgStyle={buttonStyles}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </ShareButton>
  );
};

 const Share = ({ article }) => {
  const [isReached, setIsReached] = useState(false);

  const handleScroll = () => {
    const triggerElement = document.getElementById('stop');
    const triggerElementHeight = 1300;
    if (triggerElement) {
      const triggerElementTop = triggerElement.getBoundingClientRect().top;
      const scrollY = window.scrollY;

      setIsReached(scrollY >= triggerElementTop + triggerElementHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const shareUrl = window.location.href;; // Replace with the actual URL of your article
  const title = article.title;
  const description = article.desc; // Assuming 'desc' is the property holding your article description
  

  
  const [isTwitterHovered, setIsTwitterHovered] = useState(false);
  const [isFacebookHovered, setIsFacebookHovered] = useState(false);
  const [isRedditHovered, setIsRedditHovered] = useState(false);
  const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);
  const [isEmailHovered, setIsEmailHovered] = useState(false);
  return (
    <>
    <div  className={`frame4 ${isReached ? 'fix' : ''}`} id='frame4' >   <SocialMediaButton
        Icon={XIcon}
        ShareButton={TwitterShareButton}
        shareUrl={shareUrl}
        description={description}
        hoverColor="#1A202C"
        isHovered={isTwitterHovered}
        setIsHovered={setIsTwitterHovered}
      />

      <SocialMediaButton
        Icon={FacebookIcon}
        ShareButton={FacebookShareButton}
        shareUrl={shareUrl}
        description={description}
        hoverColor="#3b5998"
        isHovered={isFacebookHovered}
        setIsHovered={setIsFacebookHovered}
      />

      <SocialMediaButton
        Icon={RedditIcon}
        ShareButton={RedditShareButton}
        shareUrl={shareUrl}
        description={description}
        hoverColor="#ff4500"
        isHovered={isRedditHovered}
        setIsHovered={setIsRedditHovered}
      />

      <SocialMediaButton
        Icon={LinkedinIcon}
        ShareButton={LinkedinShareButton}
        shareUrl={shareUrl}
        description={description}
        hoverColor="#007fb1"
        isHovered={isLinkedinHovered}
        setIsHovered={setIsLinkedinHovered}
      />

      <SocialMediaButton
        Icon={EmailIcon}
        ShareButton={EmailShareButton}
        shareUrl={shareUrl}
        description={description}
        hoverColor="#7f7f7f"
        isHovered={isEmailHovered}
        setIsHovered={setIsEmailHovered}
      />
    </div>
    </>
  )
 
 }
export default Share;
