import React , {useEffect,useState}  from 'react';


import './About.scss'
import bochrabethoughtowner from '../../../assets/bochrabethoughtowner.png'



    export const Aboutme = () => {
        return (
          <>
           <section className='section '>
                           <div className='effectImg'></div>

           <div className="aboutme caontainer ">
<div className="div ">
<div className="text-wrapper">About <span class="beth"> Bethought</span> Blog</div>
<p className="p">My name is Bochra Ouzani, a reader, essayist and an aspiring writer driven by the urge to discover, invent, and share.
    I bring to your attention the existence of buried and hidden treasures and findings from my latest trips and readings through simplified written approaches and stories.
</p>
</div>
<div className='div2'>

<div className='div22' />

<img src={bochrabethoughtowner} />
</div>

</div>
</section>
          </>
        );
      };
