import { Link, useLocation,useParams} from "react-router-dom";
import ReactPaginate from 'react-paginate';

import React, { useState, useEffect} from 'react';
import view from '../../../../assets/carbon_view.png'

import {IoMdAdd} from 'react-icons/io'
import './showarticle.scss';
import {BiTrash} from 'react-icons/bi'
import {BiEdit} from 'react-icons/bi'
import axios from "axios"

import { useContext } from 'react';
import { AuthContext } from '../../../../context/authContext';
function truncateText(text, maxLength) {
    const words = text.split(' ');
    if (words.length <= maxLength) {
      return text;
    } else {
      const truncatedWords = words.slice(0, maxLength);
      return truncatedWords.join(' ') + '...';
    }
  }
const Showarticle= ()=>{
  const {currentUser}= useContext(AuthContext);

  const [data, setData] = useState([]);

  const [currenItems, setCurrentItems]= useState([])
  
  //const articleid = location.pathname.split("/")[3] ;
  
  useEffect(()=>{
    const fetchData = async()=>{
      try{
        //const res = await axios.get(`/article/`)
        const res = await axios.get(`https://bethought-45143776de10.herokuapp.com/api/article/`)

        setData(res.data); 
                setCurrentItems(res.data.slice(0, itemsPerPage));
    
      }catch(err){
        console.log(err)
  
      }
    }
    fetchData();
   },[])

   
   const { articleid } = useParams();

  const handleDelete = async (id)=>{
    if (window.confirm('Are you sure you want to delete this article?')) {

    try {
     // console.log('Deleting article with ID:',id);

      //await axios.delete(`/article/${id}`);
      await axios.delete(`https://bethought-45143776de10.herokuapp.com/api/article/${id}`);
     // console.log('Article deleted successfully');
        alert("Article deleted successfully!");
      // Handle successful deletion or update your articles list
    } catch (err) {
      console.error('Error deleting article:', err);
      // Handle errors
    }

  }

  }

  const getText =(html) =>{
    const doc = new DOMParser().parseFromString(html,"text/html")
    return  doc.body.textContent
  }
 
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage= 4;


    useEffect(()=> {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(data.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / itemsPerPage));
    },[itemOffset,itemsPerPage,data]);

    // Invoke when user click to request another page.

    const handlePageClick = (event) => {
  const newOffset = (event.selected * itemsPerPage) % data.length;
  
  setItemOffset(newOffset);
};
  return(
    
        <> 
          <div className="newArticle">
            <div className="content">
          <div className="topDiv flex"> 
            <div className="titleText ">
                    <span className='title'> Welcome To Bethought Dashboard</span>
                    <p>hello {currentUser?.username}, Welcomeback!</p>

                </div>
               <div className=" ">
              
                </div></div> 

               <div className='ShowArticleContainer'>
               <ul role="list" className="row" >
               {currenItems.map((article) =>(
           
              <>
               <a to='/singlearticle' className='col-xl-6 col-md-6 col-sm-12' >
                <li className="ar-li">
                    <Link to={`/article/${article.id}`}> 
                    <h2 className="ar-heading ">{article.title}</h2>
                    </Link> <p className="ar-text ">
                    {getText(truncateText(article.desc, 20))}
                    
                    </p>
                   <div className="spans view"><span >{new Date(article.date).toLocaleDateString('en-US')}
                   |<img className="carbon-view" src={view} alt="" />{article.nbrView}</span> 
                   <div className="edit-delete-buttons"> 
                     <span onClick={()=>handleDelete(article.id)} > <BiTrash/></span>
                     <Link to={`/dashboard/addnewarticle?edit=${article.id}`} state={article}> <BiEdit/> </Link>
                   </div></div>
                 
                  
                </li>
            </a>
            
            
           </>
           
            ))}
            
        
            </ul>
          
               </div>
               <ReactPaginate
        breakLabel=""
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName='pagination'
        pageLinkClassName="page-num"
        previousLinkClassName='page-num prev'
        pageRangeDisplayedClassName=''
        nextLinkClassName='page-num next'
        activeLinkClassName='active'
      />
            </div>

            
        </div>
        
    </>
        )
}

export default Showarticle;
