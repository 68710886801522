import React, {useState ,useRef,useMemo} from 'react';
//import {render} from'react-dom'
import axios from "axios"
//import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';
import './addnewarticle.scss';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
//import JoditEditor from 'jodit-react';
import MyCkeditor from'./MyCkeditor.js'
// Disable the specific warning
/** 
const Font = ReactQuill.Quill.import('formats/font'); // <<<< ReactQuill exports it
Font.whitelist = ['mirza', 'roboto'] ; // allow ONLY these fonts and the default
ReactQuill.Quill.register(Font, true);

const modules= {
    toolbar:[
        [{header:[1,2,3,4,5,6,false]}],
        [{font:[]}],
        [{size:[]}],
        ["bold","italic","underline","strike","blockquote"],
        [ 
           {"list": "ordered"} ,
           {"list": "bullet"} ,
           {"indent": "-1"} ,
           {"indent": "+1"} ,   
                  
        
        ],
        ["link","image","video"],
    ]
  }
****/

const Addnewarticle= ()=>{
    const location = useLocation();
    const state = location.state;
   /*

    const config = useMemo(() => ({
        readonly: false,
        uploader: {
          url: '/upload', // Update this URL to match your server's endpoint
          format: 'json',
          method: 'POST',
          filesVariableName: "file",
          prepareData: (formData) => {
            return formData;
          },
        },
      }), [uploader]); // Include 'uploader' in the dependency array
      
      ****/
   

/*
    const upload =async ()=>{
        try {
            const formData = new FormData();
            formData.append("file",file)
            const res = await axios.post("/upload", formData)
            return res.data
            
        } catch (err) {
            console.log(err)
            
        }
    }

    const handelClick =async e=>{

        e.preventDefault()
        const imgURL = await upload();
        const articleContent = `${content} ${insertedImages.map(image => `<img src="${image.url}" alt="${image.alt}" />`).join('')}`;
     
        try {
            state
            ? await axios.put(`/article/${state.id}`, {
                title,
                desc: articleContent, 
                cat,
                img: imgURL || "",
            })
        
            :await axios.post(`/article/`, {title,
                desc: articleContent, 
                cat,
                img: imgURL || "", // Include the 'img' field with a default value
                date:moment().format("YYYY-MM-DD"),});
              console.log("Article has been created.");
              

            
          } catch (err) {
            console.error('Error:', err);}
       const imgURL= upload()
       try {
        state 
        ? await axios.put(`/article/${state.id}`,{
            title,desc:value,cat,img:file ? imgURL : ""
        })
        :  await axios.post(`/article/`,{
            title,
            desc:value,
            cat,
            img:file ? imgURL : "",
            date: moment(Date.now()).format("YYYY--MM--DD HH:mm:ss")
        })
        
       } catch (err) {
        console.log(err)
       }
       
    }
    
****/
    
    return(
    
        <>  

<MyCkeditor  state={state}/>
{/****  <div className="newArticle" >
            <div className="content">
                <div className="" >

     </div>
            </div>
 
            <div className="menu ">
                <div className="item">
                <h1>Publish</h1>
                <span>
                    <b>Satatus:</b>Draft
                </span>
                <span>
                    <b>Visibility</b>Public
                </span>
                <input style={{display:"none"}} type="file" id='file' name="" onChange={e=>setFile(e.target.files[0])} />
                <label className='file' htmlFor='file'>Upload File</label>
                <div className="buttons">
                    <button>Save as Draft</button>
                    <button onClick={handelClick} id='publishButton'>Publish</button>
                </div>


                </div>
                <div className="item">
                    <h1>Category</h1>
                    <div className="cat">
                        <input type="radio" checked={cat === "art"} name='cat' value="art" id="art" onChange={e=>setCat(e.target.value)}/>
                    <label htmlFor="art">Art</label>
                    </div>
                    <div className="cat">
                    <input type="radio" name='cat' checked={cat === "love"} value="love" id="Love" onChange={e=>setCat(e.target.value)}/>
                    <label htmlFor="love">Love</label>
                    </div>
                    <div className="cat">
                    <input type="radio" checked={cat === "travel"} name='cat' value="travel" id="travel" onChange={e=>setCat(e.target.value)}/>
                    <label htmlFor="travel">Travel</label>
                    </div>
                </div>

            </div>
           
        </div> */}
        
        
   
    </>
        )
}

export default Addnewarticle;