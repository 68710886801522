import React , {useState}  from 'react';
import {useNavigate} from 'react-router-dom';
import axios from "axios"

import logo from '../../assets/logo.png';
const  ResetPassword  = ()=>{
	
	const [inputs,setInputs]=useState({
		
		password:"",
		confirmPassword:"", 
		otp:"",
	})
	const [err,setError] = useState(null)
	const handelChange = e =>{
		setInputs(prev =>({...prev,[e.target.name]:e.target.value}))
	
	} 
	const navigation = useNavigate()
	
	const handelSubmit= async (e)=>{
		e.preventDefault();
	
	  const data = {
  password: inputs.password,
  confirmPassword: inputs.confirmPassword,
  otp: inputs.otp,
};

	  
	
	  try {
		const response =  await axios.post('/auth/resetPassword', data);
		    console.log('Server Response:', response);

    
        if (response.status === 200) {
          // Request was successful, show a success message or redirect the user.
          alert('Password update successfully');
	navigation('/login');

        } else {
          // Handle the case where the request was not successful (e.g., show an error message).
          console.error('Failed to update password');
        }
	
		
	  } catch (err) {
		setError(err.response.data);
		    console.error('Error in axios request:', err);

	  }
	}
		return(
			<>
			 <div className="auth ftco-section"  >
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-6 text-center mb-5">
						<h2 className="heading-section">
				<img src={logo} alt=""/>
			  </h2>
					</div>
				</div>
				<div className="row justify-content-center">
			
					<div className="col-md-6 col-lg-5">
			<h3 className="text-center mb-4 text-white">Reset Password</h3>
	
						<div className="login-wrap p-4 p-md-5">
					  <div className="icon d-flex align-items-center justify-content-center">
					  </div>
							<form  className="login-form" method="post" >
							
							<div className="form-group">
							  <input type="text" className="form-control rounded-left" placeholder="Enter a new password" name="password"  required onChange={handelChange} />
						  </div>
						  <div className="form-group">
							  <input type="text" className="form-control rounded-left" placeholder="Confirme your password" name="confirmPassword"  required  onChange={handelChange}/>
						  </div>
								  <div className="form-group">
							  <input type="text" className="form-control rounded-left" placeholder=" Enter OTP" name="otp"  required  onChange={handelChange}/>
						  </div>
				 
					<div className="form-group">
						<button  type="submit" name="submit" className="btn btn-primary rounded submit p-3 px-5" onClick={handelSubmit} >Submit </button>
					</div>
				  </form>
				</div>
					</div>
				</div>
			</div>
		</div>
			</>
		)
	}

export default ResetPassword;
