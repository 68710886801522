


import React , {useEffect}  from 'react';
import { Link} from "react-router-dom";

import { useState } from "react";

import axios from "axios"

import './Article.scss';
import {AiOutlineEye} from 'react-icons/ai'

function truncateText(text, maxLength) {
  const words = text.split(' ');
  if (words.length <= maxLength) {
    return text;
  } else {
    const truncatedWords = words.slice(0, maxLength);
    return truncatedWords.join(' ') + '...';
  }
}
const Lastarticle = ()=>{
  

  const [latestArticle, setLatestArticle]= useState(null)
  const [clickCount, setClickCount] = useState(0);

  const incrementViewCount = () => {
    setClickCount(clickCount + 1);
  };

  useEffect(()=>{
    const fetchData = async()=>{
      try{
        const res = await axios.get('https://bethought-45143776de10.herokuapp.com/api/article/')
        //const res = await axios.get('/article/')
        // "proxy": "http://localhost:3001/api"
                console.log('API Response:', res.data); // Log the response data
                if (res.data.length > 0) {
                  setLatestArticle(res.data); // Initially display first 'itemsPerPage' items
                  setClickCount(res.data[0].nbrView);
              }
       // setLatestArticle(res.data); // Initially display first 'itemsPerPage' items
        //setClickCount(res.data[0].nbrView);
    
      }catch(err){
        console.log(err)
        console.log("error finding error")
  
      }
    }
    fetchData();
   },[])
   const getText =(html) =>{
    const doc = new DOMParser().parseFromString(html,"text/html")
    return  doc.body.textContent
  }
  const updateClickCount = async (id) => {
    try {
      const response = await axios.put(`https://bethought-45143776de10.herokuapp.com/api/article/updateClickCount/${id}`, {
        nbrView: 1, // Set the newClickCount value to 1, or any other value you want to increment by
      });
      // Handle the response if needed
      if (response.status === 200) {
        setClickCount(clickCount + 1); // Update the local click count
      }
    } catch (error) {
      // Handle errors
      console.log("no updating count")
      console.log("Error updating count", error);
  
    }
  };
    return(
        <>
       
<div className="sectionContainer grid row">

<h1 className='title'>Latest Articles</h1>



{latestArticle && latestArticle.length > 0 && (
         console.log("lastarticle",latestArticle[latestArticle.length - 1]),
              <>
<div   className="imgDiv  col-xl-6 col-md-12" key={latestArticle[latestArticle.length - 1].id}>
 

  <img src={latestArticle[latestArticle.length - 1].img} alt="" />
</div>
<div  className='textDiv col-xl-4 '>
<span className='gridTitle'>
      Posted on  {new Date(latestArticle[latestArticle.length - 1].date).toLocaleDateString('en-US')} 
      
      </span >
  <h2 >
  {latestArticle[latestArticle.length - 1].title}
    </h2>


  <div className="grids ">
  <div   className="singleGrid">
  <div className="gridTextContainer">
   <p className="gridText">
   {getText( truncateText(latestArticle[latestArticle.length - 1].desc, 50))}  

</p>
</div>
      
      <span className='gridTitle flex'><AiOutlineEye/>  {latestArticle[latestArticle.length - 1].nbrView} </span>
     
      
  </div>
  <Link to={`/article/${latestArticle[latestArticle.length - 1].id}/${latestArticle[latestArticle.length - 1].title}`}
  onClick={() => {
    updateClickCount(latestArticle[latestArticle.length - 1].id)
    incrementViewCount();
  }}>  <input type='submit' className='readButton' value='Read more'/></Link> 

</div>
</div>
</>
)}

</div>

</>

)
}

export default Lastarticle;
