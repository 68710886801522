import React , {useState}  from 'react';
import { useContext } from 'react';

import './Profile.scss';
import { AuthContext } from '../../../../context/authContext';
import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import axios from "axios"

const Profile = ()=>{
	const {currentUser}= useContext(AuthContext);

	
	const { userId } = useParams();
const [username, setUserName] = useState('');
const [email, setEmail] = useState('');
const [currentPassword, setCurrentPass] = useState('');
const [newPassword, setNewpass] = useState('');
const [image, setImage] = useState(null);
	

useEffect(()=>{
	axios.get('/auth/infoprofile/'+ userId).then
	(res=>{
	   console.log(res)
	   setUserName(res.data[0].username);
	   setEmail(res.data[0].email);
	   setNewpass(res.data[0].password);
	   })
	   .catch(err =>console.log(err));
	   },[])
   
	   const handleFileChange = (e) => {
		   const file = e.target.files[0];
		   setImage(file);
		 };
		 const handleSubmit = (e) => {
		   e.preventDefault(); 
		   axios.post(`/auth/checkpassword/${userId}`, { currentPassword })
		   .then(checkRes => {
			 if (checkRes.data.valid) {
			   // Current password is valid, proceed with the update
			   const formData = new FormData();
			   formData.append('username', username);
			   formData.append('email', email);
			   formData.append('newPassword', newPassword);
			   formData.append('avatar', image);
			   axios.put('/auth/updateuser/'+ userId, formData, {
				   headers: {
					 'Content-Type': 'multipart/form-data',
				   },
			 })
				 .then(res => {
				   if (res.data.updated) {
					 alert('User information has been updated.');
				   }
				 });
			 } else {
			   // Current password is not valid
			   console.log('cuurent pass worng')
			   window.alert('Current password is incorrect. Please enter the correct current password.');
			 }
		   })
		   .catch(err => console.error('Error checking current password:', err));
	   
		   
		 };
		 
	  
    return(

        <>  
         <section className="auth profile-section">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-3 text-center mb-5">
            <img src={currentUser?.img} alt=""/>
          
				</div>
			</div>
			<div className="row justify-content-center">
        
				<div className="col-xl-6  col-md-12 col-lg-6">

					<div className="login-wrap p-4 p-md-5">
		      	<div className="icon d-flex align-items-center justify-content-center">
		      	</div>
				  <form  className="login-form" method="post" onSubmit={handleSubmit}>
		      		<div className="form-group">
		      			<input type="text" className="form-control rounded-left" placeholder=" enter new Username" name="username" value={username}  required  onChange={e => setUserName(e.target.value)}/>
		      		</div>
                      <div className="form-group">
		      			<input type="email" className="form-control rounded-left" placeholder="enter new Email" name="email" value={email}  required  onChange={e => setEmail(e.target.value)}/>
		      		</div>
	            <div className="form-group d-flex">
	              <input type="password" className="form-control rounded-left" placeholder="enter Current password" name="currentPassword"  value={currentPassword} onChange={e => setCurrentPass(e.target.value)} />
	            </div>
	            <div className="form-group d-flex">
	              <input type="password" className="form-control rounded-left" placeholder=" enter new password " name="newPassword"  value={newPassword} onChange={e => setNewpass(e.target.value)}/>
	            </div>
				<input
          type="file"
          name="avatar"
		 onChange={handleFileChange}
        />
				
	            <div className="form-group">
	            	<button type="submit" name="submit" className="btn btn-primary rounded submit p-3 px-5" >Edit</button>
	            </div>
	          </form>
	        </div>
				</div>
			</div>
		</div>
	</section>
            
            </>
        )
}

export default Profile;