import { useEffect, useState } from "react";
import { createContext } from "react";
import axios from "axios"

export const AuthContext =createContext()
export const AuthContextProvider = ({children})=>{
    const [currentUser, setCurrentUser] =useState(JSON.parse(localStorage.getItem("user"))|| null);
     const login =async(inputs)=>{

      try {
        const response = await axios.post('/auth/login', inputs);
    
        if (response.status === 200) {
          const userData = response.data; // Include the password property if it's returned by the API
          setCurrentUser(userData);

        } else {
          // Handle login failure
        }
      } catch (error) {
        // Handle errors
      }
       //const res = await axios.post(`/auth/login`, inputs)
       //setCurrentUser(res.data)
     };
     const logout =async(inputs)=>{
        await axios.post("https://bethought.onrender.com/api/auth/logout");
        

        setCurrentUser(null);
      };
      useEffect( ()=>{
        localStorage.setItem("user", JSON.stringify(currentUser));

      },[currentUser]);
      return <AuthContext.Provider value={{currentUser, login ,logout}}> {children}</AuthContext.Provider>
     
}
