
import React from 'react'
import { useState } from 'react';
import axios from "axios"
import logo from '../../assets/logo.png'

import {Link, useNavigate} from "react-router-dom";
  

import './login.scss';
const Register  = ()=>{
const [inputs,setInputs]=useState({
	username:"",
	email:"",
	password:"",
})
const [file, setFile] = useState(null);

const handleFileChange = (e) => {
  const selectedFile = e.target.files[0];
  setFile(selectedFile);
};
const [err,setError] = useState(null)

const navigation = useNavigate()

const handelChange = e =>{
	setInputs(prev =>({...prev,[e.target.name]:e.target.value}))

}
const handelSubmit= async (e)=>{
	e.preventDefault();

  const formData = new FormData();
  formData.append('username', inputs.username);
  formData.append('email', inputs.email);
  formData.append('password', inputs.password);
  formData.append('avatar', file);

  try {
    await axios.post('/auth/register', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    navigation('/login');
  } catch (err) {
    setError(err.response.data);
  }
}


    return(
        <>
			
		
        <section className="auth ftco-section">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-md-6 text-center mb-5">
					<h2 className="heading-section">
            <img src={logo} alt=""/>
          </h2>
				</div>
			</div>
			<div className="row justify-content-center">
        
				<div className="col-md-6 col-lg-5">
        <h3 className="text-center mb-4 text-white">welcome to Bethought Dashboard</h3>

					<div className="login-wrap p-4 p-md-5">
		      	<div className="icon d-flex align-items-center justify-content-center">
		      	</div>
						<form  className="login-form" method="post">
		      		<div className="form-group">
		      			<input type="text" className="form-control rounded-left" placeholder="Username" name="username"  required onChange={handelChange}/>
		      		</div>
                      <div className="form-group">
		      			<input type="email" className="form-control rounded-left" placeholder="Email" name="email"  required onChange={handelChange}/>
		      		</div>
	            <div className="form-group d-flex">
	              <input type="password" className="form-control rounded-left" placeholder="Password" name="password"  required onChange={handelChange}/>
	            </div>
				<input type="file" className="form-control rounded-left"  name="avatar"  required  
    accept=".jpg, .jpeg, .png"
   
    onChange={handleFileChange}/>

				
	            <div className="form-group d-md-flex">
	            	<div className="w-50 text-md-left">
	            		<Link to="../login" className="have_account checkbox-primary">have an account?
									 
									</Link>
								</div>
								<div className="w-50 text-md-right">
	           
								</div>
								
								
	            </div>
				
	            <div className="form-group">
	            	<button onClick={handelSubmit} type="submit" name="submit" className="btn btn-primary rounded submit p-3 px-5">Get Started</button>
	            </div>
	          </form>
	        </div>
				</div>
			</div>
		</div>
	</section>
	
        </>
    )
}


export default Register;
