import React , {useState}  from 'react';
import {Link} from "react-router-dom";
import axios from "axios"

import './subsecribe.scss';
import PropTypes from 'prop-types';


function Subcecribe({isCoPage }){
const [inputs,setInputs]=useState({
   
        email:"",
      
      })
    
        const handelChange = e =>{
          setInputs(prev =>({...prev,[e.target.name]:e.target.value}))
        
        }
      const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
          email: inputs.email,
        };
        
        try {
            const response = await axios.post('/other/subscriptions', data);
            console.log('Response:', response);
          
            if (response.data && response.data.message === 'Subscription successful!') {
              console.log('Subscription successful');
              alert('Subscription completed successfully, thank you!');
            } else {
              console.error('Failed to Subscribe');
              alert('Failed to subscribe. Please try again.');
            }
          } catch (error) {
            console.error('An error occurred:', error);
            alert('An error occurred. Please try again later.');
          }
          
      };
    return(
        <>

<div className={`sub ${isCoPage ? 'sub-reso' : ''} `} id='sub'>
                  
                    <div className="subContent">
                    <div className="label">
<p className="text-wrapper">Finding this to be interesting? Want to read more?</p>
<p className="text-wrapper">Subscribe and get notified whenever a new content is published!</p>


</div>
<div className="subSearch">
    <div className="searchDiv">
        <input type="text" className='searchInput' name="email" placeholder='enter your email' required onChange={handelChange}/>
        
    </div>
    <input type='submit' className='searchButton' value='Subscribe' onClick={handleSubmit}/>
</div>
</div>
                        
                </div>
    </>
    )
    
}
Subcecribe.propTypes = {
    isCoPage: PropTypes.bool.isRequired,
  };
export default Subcecribe;
