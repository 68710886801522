import React,{useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import logo from '../assets/logo.png';
import {AiFillCloseCircle} from 'react-icons/ai';
import {TbGridDots} from 'react-icons/tb';
import './Navbar.scss';
import styles from './Navbar.scss';
import About from '../pages/bloge/About/About'
import PropTypes from 'prop-types';

function Navbar({isArPage }){
// code to toggle / show navBaar
const [active, setActive] = useState('navBar')
const [scrolling, setScrolling]= useState(false)
const showNav =()=>{
  setActive('navBar activeNavBar')
}
// code to remove NavBar
const removeNav =()=>{
  setActive('navBar')
}

useEffect(() => {
  const handleScroll = () => {
    if (window.scrollY >0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    return(
        <>


<section className={`nevBarSection ${scrolling ? 'scrolled' : ''} `}>
<div className={`header  ${scrolling ? 'scrolled ' : '' } ${isArPage ? 'specific_header' : ''} `}>
  <div className="logoDiv">
     <img src={logo} alt="" />

  </div>

  <div className={active}>
    <ul className="navLists  flex">
      <li className='navItem'>
        <Link className='navLink' to="/home">Home</Link>
      </li>
      <li className='navItem'>
        <Link className='navLink' to="/articles" >Articles</Link>
      </li>
      <li className='navItem'>
        <Link className='navLink' to="/about">About</Link>
      </li>   <li className='navItem'>
      <Link className='navLink'  to="/contact">Contact</Link>
      </li>

    </ul>
    <div onClick={removeNav} className="closeNavBar">
    
    <AiFillCloseCircle className='icon'/>
    </div>
  </div>

<div onClick={showNav} className="toggleNavbar">
  <TbGridDots className='icon'/>
</div>

</div>
</section>

         </>
    )
}

Navbar.propTypes = {
  isArPage: PropTypes.bool.isRequired,
};
export default Navbar;