import React , {useState, useEffect, useRef  }  from 'react'
import {Link} from "react-router-dom";

import './Footer.scss';
import logo from '../assets/logo-black.svg'
import pin from '../assets/pinterest.svg'
import insta from '../assets/nstagram.svg'
import twitter from '../assets/twitter.svg'
import face from '../assets/facebook.svg'

 const Footer  = ()=>{
    
    return(
        
        
      
                <div  className="footer" id='footer'>
                    <div className="rectangle" >
                    <div className="footerContent">
        <img className="bethought-white" alt="Bethought white" src={logo}/>
   
   <div className="navbar">

         <div className="text-wrapper"> <Link to='/about' className='link'>About </Link></div>
<div className="text-wrapper"> <Link to='/contact' className='link'> Contact</Link></div>
<div className="text-wrapper"> <Link to ='/Oops'className='link' >Terms Of Service</Link></div>
<div className="text-wrapper"><Link to ='/Oops' className='link'>Privacy Policy </Link></div>
</div>
<p className="p">© 2023 Bethought. All rights reserved</p>
</div>
                        </div>
                </div>
            );
        };

export default Footer;
